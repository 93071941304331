import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Chat from './components/Chat';
import Resume from './pages/Resume';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { routes } from './routes';

ReactDOM.render(
    <Router>
      <Route exact path={routes.home.path} component={App}/>
      <Route exact path={routes.resume.path} component={Resume}/>
      <Route exact path={routes.chat.path} component={Chat}/>
    </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
