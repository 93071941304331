import './css/Footer.css';

function Footer() {
    return (
        <footer>
            <p>Lucas Balestero</p>
            <p><a href="mailto:lucasbalestero@gmail.com">lucasbalestero@gmail.com</a></p>
        </footer>
    )
}

export default Footer;