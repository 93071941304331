import React, { useEffect, useRef, useState } from 'react';
import './css/Chat.css';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyBDPyqZ9eBUWGgsXlEU2AhWvdVaYJ4nKKk",
  authDomain: "chat-f9245.firebaseapp.com",
  projectId: "chat-f9245",
  storageBucket: "chat-f9245.appspot.com",
  messagingSenderId: "474843860143",
  appId: "1:474843860143:web:af44acb6325c552b57985a"
});

const auth = firebase.auth();
const firestore = firebase.firestore();


function Chat() {

  const [user] = useAuthState(auth);

  return (
    <div className="Chat">
      <header>
        <SignOut />
      </header>

      <section>
        { user && (user.email === 'lucasbalestero@gmail.com' || user.email === 'nathalyandradecs@gmail.com' )
            ? <ChatRoom /> : <SignIn />}
      </section>
    </div>
  );
}

function SignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }

  return (
    <button className="login" onClick={signInWithGoogle}>Login</button>
  )
}

function SignOut() {
  return auth.currentUser && (
    <button onClick={() => auth.signOut()}>Logout</button>
  )
}


function ChatRoom() {
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt').limitToLast(25);

  const [messages] = useCollectionData(query, {idField: 'id'});

  const dummy = useRef();

  useEffect( () => {
    dummy.current.scrollIntoView({behavior: 'smooth'});
  }, [messages]);


  const [formValue, setFormValue] = useState('');

  const sendMessage = async(e) => {
    e.preventDefault();

    const {uid, photoURL, displayName} = auth.currentUser;

    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      name: displayName,
      uid,
      photoURL
    })

    setFormValue('');
  }

  return (<>
    <main>
      { messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}

      <span ref={dummy}></span>

    </main>
    <form onSubmit={sendMessage}>
      
      <input type="text" value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="..." />
      
      <button type="submit" disabled={!formValue}>»</button>

    </form>
  </>)
}


function ChatMessage(props) {
  const { text, uid, name, createdAt } = props.message;
  let date = null;
  if (createdAt) {
    date = new Date(1970, 0, 1);
    date.setSeconds(createdAt.seconds);
  }

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

  return (<>
    <div className={`message ${messageClass}`}>
      <h6>{name}</h6>
      <small>{date && date.toLocaleString()}</small>
      <p>{text}</p>
    </div>
  </>)
}


export default Chat;
