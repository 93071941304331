import './css/Header.css';

function Header() {
    return (
        <header>
            <h3>Lucas Balestero's website</h3>
            <nav>
                <a href="https://www.linkedin.com/in/lucasbalestero/">linkedin</a>
                <a href="https://github.com/lucasbalestero">github</a>
                <a href="https://www.instagram.com/balestel/">instagram</a>
                <a href="https://www.facebook.com/lucas.balestero">facebook</a>
                <a href="/resume">resume</a>
            </nav>
        </header>
    )
}

export default Header;