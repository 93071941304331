import './css/Resume.css';

function Resume() {
    return (
        <div class="container">
            
            <h2>Lucas Balestero Santiago Silva</h2>
            <p class="m0"><a href="https://balestero.com">balestero.com</a></p>
            <p class="m0"><a href="mailto:lucasbalestero@gmail.com">lucasbalestero@gmail.com</a></p>
            
            <h3>SUMMARY</h3>
            <p>I have been working with Software Development for the past 6 years, with microservices, monoliths,
                and even desktop and mobile games. The more I learn the more I feel like I've found my place in the world.
                I’m passionate about learning technology, trying things myself, and doing useful things with my knowledge.</p>
            
            <h3>EXPERIENCE</h3>
            <h3>Software Developer at Agence <span class="h3__date">(Jul. 2020 - Currently)</span></h3>
            <p>I’ve been working in an agile squad using Scrum. Doing activities such as adjusting the systems to the customer needs, 
                writing unit tests, cleaning the code and making it more scalable and maintainable. 
                Adopted a development flow using git and code review. Drawn BPMN processes. 
                Wrote APIs in SOAP and REST.</p>
            
            <h3>Systems Analyst and Developer at AGETEC <span class="h3__date">(May 2014 - Jul. 2020)</span></h3>
            <p>Working at the City Hall. Worked with a great variety of government systems, 
                in different technologies and different architectures such as microservices and monoliths. 
                Wrote REST APIs, scheduled jobs, and automated lots of tasks. 
                Contributed in the adoption of microservices architecture, Dockerized applications, 
                continuous integration and deployment, and moving to GIT. I also made BPMN diagrams and wrote user stories.</p>
            
            <h3>EDUCATION</h3>
            <h3 class="mb0">Associate Degree in Systems Analysis and Development</h3>
            <p class="m0">Uniderp</p>
            <p class="m0">Jan 2012 - July 2014</p>
                
                

            <h3>SKILLS</h3>
            <p>Java, Spring, Angular, JavaScript, CSS, Python, Go, Git, SQL, Docker, Linux</p>
        </div>
    );
}

export default Resume;